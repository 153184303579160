export const TRANSLATION_KEY = 'notifications';

export const NOTIFICATION_SOCKET_EMIT_ENUM = {
  GetNotifications: 'getNotifications',
  MarkMessageAsRead: 'markMessageAsRead',
  MarkAllMessageAsRead: 'markAllMessageAsRead',
};

export const NOTIFICATION_SOCKET_EVENT_ENUM = {
  NewNotification: 'newNotification',
};

export const NOTIFICATIONS_EVENTS = {
  APPLICANT_CREATED: 'APPLICANT_CREATED',
  APPLICANT_IN_PROGRESS: 'APPLICANT_IN_PROGRESS',
  APPLICANT_INCOMPLETE: 'APPLICANT_INCOMPLETE',
  APPLICANT_IN_REVIEW: 'APPLICANT_IN_REVIEW',
  APPLICANT_REJECTED: 'APPLICANT_REJECTED',
  APPLICANT_APPROVED: 'APPLICANT_APPROVED',
  APPLICANT_COMPLETED: 'APPLICANT_COMPLETED',
  APPLICANT_AML_UPDATED: 'APPLICANT_AML_UPDATED',
  APPLICANT_STEP_PASSED: 'APPLICANT_STEP_PASSED',
  APPLICANT_DUPLICATE_DOCUMENT: 'APPLICANT_DUPLICATE_DOCUMENT',
  EXPIRED_DOCUMENT: 'EXPIRED_DOCUMENT',
  ADDITIONAL_DOCUMENT_STEP_ERROR: 'ADDITIONAL_DOCUMENT_STEP_ERROR',
  APPLICANT_DUPLICATE_EMAIL: 'APPLICANT_DUPLICATE_EMAIL',
  APPLICANT_DUPLICATE_PHONE: 'APPLICANT_DUPLICATE_PHONE',
  AML_POSITIVE_CASES: 'AML_POSITIVE_CASES',
  AML_CANNOT_STARTED: 'AML_CANNOT_STARTED',
  AML_RESULT_UPDATED: 'AML_RESULT_UPDATED',
};

export const NOTIFICATIONS_EVENTS_PRIORITY = {
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const NOTIFICATIONS_WITH_HIGH_PRIORITY = [
  NOTIFICATIONS_EVENTS_PRIORITY.CRITICAL,
  NOTIFICATIONS_EVENTS_PRIORITY.HIGH,
];
