import { useEffect, useState } from 'react';

import MatAvatar from '@mui/material/Avatar';
import { useGetImageUrl } from 'app/hooks/useGetImageUrl';

const Avatar = ({ path, type, src, size, ...props }) => {
  const fetchImage = useGetImageUrl(type, path);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (!path || !type) return;

    fetchImage().then(setImageUrl);
  }, [path, type]);

  return <MatAvatar sx={{ height: size, width: size }} src={src || imageUrl} {...props} />;
};

export default Avatar;
