import { useController } from 'react-hook-form';
import { FormControlLabel, Switch, Typography } from '@mui/material';

const ControlledSwitch = ({
  labelProps,
  labelPlacement = 'end',
  customLabel,
  name,
  control,
  label,
  defaultValue,
  ...restProps
}) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  const handleChange = (e) => {
    const { checked } = e.target;
    const valueTarget = checked ? defaultValue : '';
    onChange(defaultValue ? valueTarget : checked);
  };

  return (
    <FormControlLabel
      {...labelProps}
      className="m-0"
      labelPlacement={labelPlacement}
      control={<Switch {...restProps} checked={!!value} onChange={handleChange} />}
      label={
        customLabel || (
          <Typography marginLeft={labelPlacement === 'end' ? '5px' : 0} variant="subtitle2">
            {label}
          </Typography>
        )
      }
    />
  );
};

export default ControlledSwitch;
