import { NOTIFICATIONS_EVENTS_PRIORITY } from '../constants';

export const groupByPriority = (notifications) =>
  notifications.reduce(
    (acc, curr) => {
      acc[curr.priority].push(curr);

      return acc;
    },
    {
      [NOTIFICATIONS_EVENTS_PRIORITY.CRITICAL]: [],
      [NOTIFICATIONS_EVENTS_PRIORITY.HIGH]: [],
      [NOTIFICATIONS_EVENTS_PRIORITY.MEDIUM]: [],
      [NOTIFICATIONS_EVENTS_PRIORITY.LOW]: [],
    }
  );
