import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DIALOGS from 'app/constants/dialogs';
import { selectDialogOptions } from 'app/store/fuse/dialogSlice';
import Dialog from '../Dialog';

const ConfirmDialog = () => {
  const options = useSelector(selectDialogOptions);
  const {
    title,
    content,
    description,
    submitBtnText,
    submitButtonProps,
    variant = 'secondary',
    ...rest
  } = { ...options };

  return (
    <Dialog
      id={DIALOGS.CONFIRM_DIALOG}
      title={title}
      submitButtonProps={{
        ...submitButtonProps,
        color: variant,
        children: submitBtnText || submitButtonProps?.children,
      }}
      content={
        <Box>
          <Typography variant="body2">{description}</Typography>
          {content}
        </Box>
      }
      {...rest}
    />
  );
};

export default ConfirmDialog;
