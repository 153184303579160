import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import _snakeCase from 'lodash/snakeCase';
import { showMessage } from 'app/store/fuse/messageSlice';
import { refreshTokenLogin } from 'src/app/auth/store/loginSlice';
import { logoutUser } from 'src/app/auth/store/userSlice';
import store from 'src/app/store';
import { AxiosManager } from 'src/app/services/axiosManager';
import { ExceptionCode } from 'src/app/constants/exceptionCode';

import en from './i18n/en';
import ua from './i18n/ua';

i18next.addResourceBundle('en-US', 'common-errors', en);
i18next.addResourceBundle('uk-UA', 'common-errors', ua);

export default function Notification() {
  const { t } = useTranslation('common-errors');
  const handleShowErrorMessage = (text) =>
    store.dispatch(
      showMessage({
        message: text,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'error',
      })
    );

  useEffect(() => {
    const excludedKeys = ['AuthError', 'Unauthorized', 'Forbidden'];
    AxiosManager.bindHttpErrorsHandlers(
      new Map([
        // REPLACE WITH 403
        [ExceptionCode.AuthError, () => store.dispatch(refreshTokenLogin())],
        [ExceptionCode.Unauthorized, () => store.dispatch(logoutUser())],
        ...Object.entries(ExceptionCode)
          .filter(([key]) => !excludedKeys.includes(key))
          .map(([key, value]) => [value, () => handleShowErrorMessage(t(_snakeCase(key).toUpperCase()))]),
      ])
    );
  }, []);

  return null;
}
