import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

const PATHS_APPLICANTS = '/applicants';

class VerificationsService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_SERVICE);
  }

  async _baseAmlScanRequest(endpoint, body = {}, method = 'get') {
    const { callback, ...rest } = { ...body };

    try {
      const {
        data: { data },
      } = await this.axios[method](`${PATHS_APPLICANTS}/${endpoint}`, rest);

      callback?.();

      return data;
    } catch (err) {
      callback?.(err?.response?.statusText);
      throw AxiosManager.stringifyError(err);
    }
  }

  getDeviceGeo({ applicantId }) {
    return this._baseAmlScanRequest(`${applicantId}/device-geo`);
  }

  getDeviceGeoById({ applicantId, geoId }) {
    return this._baseAmlScanRequest(`${applicantId}/device-geo/${geoId}`);
  }

  async getAll(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get(PATHS_APPLICANTS, { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateFullName({ fullName, callback, applicantId }) {
    try {
      const {
        data: { data },
      } = await this.axios.patch(`${PATHS_APPLICANTS}/${applicantId}/update-full-name`, { fullName });

      callback?.();
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getById(id) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${id}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async assignReviewer({ id, callback, ...restParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${id}/review/assign`, { ...restParams });
      if (typeof callback === 'function') {
        callback();
      }
      return { id, changes: { review: data } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async batchAssign({ form, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/review/batch-assign`, form);
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async approve({ id, ...restParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${PATHS_APPLICANTS}/${id}/review/approve`, { ...restParams });
      return { id, changes: { review: data } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async decline({ id, ...restParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${PATHS_APPLICANTS}/${id}/review/reject`, { ...restParams });
      return { id, changes: { review: data } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async delete({ applicantId, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`${PATHS_APPLICANTS}/${applicantId}`);
      if (typeof callback === 'function') {
        callback();
      }
      return applicantId;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getHistory({ id, queryParams, service }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${id}/services/${service}`, { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getLogs({ id, queryParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${id}/histories`, { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async moveToFlow({ id, flowId }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${id}/moveToFlow`, { flowId });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async resolveWarnings({ applicantId, body }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${PATHS_APPLICANTS}/${applicantId}/review/resolve-warning`, body);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async createVerificationLink({ applicantId, body }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${applicantId}/token`, body);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async scheduleApplicantDeletion({ applicantId }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${applicantId}/schedule-deletion`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async restoreApplicant({ applicantId }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${applicantId}/restore`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deactivateService({ service, id, comment }) {
    const url = `${PATHS_APPLICANTS}/${id}/services/${service}/deactivate`;
    try {
      const {
        data: { data },
      } = await this.axios.post(url, { comment });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async activateService({ id, service, serviceId, comment }) {
    const url = `${PATHS_APPLICANTS}/${id}/services/${service}/activate/${serviceId}`;
    try {
      const {
        data: { data },
      } = await this.axios.post(url, { comment });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new VerificationsService();
