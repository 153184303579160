import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import socketIOClient from 'socket.io-client';

import { selectors } from 'app/store/modeSlice';
import { useEffectOnce } from 'app/hooks/useEffectOnce';
import { ACCESS_TOKEN_KEY } from 'app/services/jwtService';

import { NOTIFICATION_SOCKET_EMIT_ENUM, NOTIFICATION_SOCKET_EVENT_ENUM } from '../constants';

export const useNotificationsWebSocket = () => {
  const [total, setTotal] = useState(0);
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const currentMode = useSelector(selectors.selectModeType);

  useEffectOnce(() => {
    const newSocket = socketIOClient(`${process.env.REACT_APP_ADMIN_API_URL}/workplace`, {
      transports: ['websocket'],
      path: '/ws/',
      auth: { token: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`, mode: currentMode },
      reconnect: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.off(NOTIFICATION_SOCKET_EVENT_ENUM.NewNotification);
        newSocket.disconnect();
      }
    };
  }, [currentMode]);

  useEffect(() => {
    if (socket) {
      const event = NOTIFICATION_SOCKET_EVENT_ENUM.NewNotification;
      socket.on(event, (data) => {
        setNotifications((prev) => {
          if (!_.find(prev, { id: data.id })) {
            return [data, ...prev];
          }
          return prev;
        });
        setTotal((prev) => prev + 1);
      });

      // Cleanup to avoid duplicate listeners
      return () => socket.off(event);
    }
  }, [socket, notifications]);

  const emitter = (event) => (data) => {
    if (socket) {
      return new Promise((resolve, reject) => {
        socket.emit(event, data, (response) => {
          if (response.statusCode === 200) {
            return resolve(response);
          }

          return reject(response);
        });
      });
    }
  };

  return {
    total,
    socket,
    setTotal,
    notifications,
    setNotifications,
    getNotifications: emitter(NOTIFICATION_SOCKET_EMIT_ENUM.GetNotifications),
    markMessageAsRead: emitter(NOTIFICATION_SOCKET_EMIT_ENUM.MarkMessageAsRead),
    markAllMessageAsRead: emitter(NOTIFICATION_SOCKET_EMIT_ENUM.MarkAllMessageAsRead),
  };
};
