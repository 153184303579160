import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const HeroIconsOutline = ({ iconId, ...rest }) => (
  <FuseSvgIcon {...rest}>{`heroicons-outline:${iconId}`}</FuseSvgIcon>
);
const HeroIconsSolid = ({ iconId, ...rest }) => (
  <FuseSvgIcon {...rest}>{`heroicons-solid:${iconId}`}</FuseSvgIcon>
);
const FilesIcons = ({ iconId, ...rest }) => <FuseSvgIcon {...rest}>{`files-icons:${iconId}`}</FuseSvgIcon>;
const CommonIcons = ({ iconId, ...rest }) => <FuseSvgIcon {...rest}>{`common:${iconId}`}</FuseSvgIcon>;
const MaterialSolid = ({ iconId, ...rest }) => (
  <FuseSvgIcon {...rest}>{`material-solid:${iconId}`}</FuseSvgIcon>
);
const MaterialOutline = ({ iconId, ...rest }) => (
  <FuseSvgIcon {...rest}>{`material-outline:${iconId}`}</FuseSvgIcon>
);
const Workflow = ({ iconId, ...rest }) => <FuseSvgIcon {...rest}>{`workflow:${iconId}`}</FuseSvgIcon>;
const Feather = ({ iconId, ...rest }) => <FuseSvgIcon {...rest}>{`feather:${iconId}`}</FuseSvgIcon>;
const Status = ({ iconId, ...rest }) => <FuseSvgIcon {...rest}>{`status:${iconId}`}</FuseSvgIcon>;
const NotificationsIcons = ({ iconId, ...rest }) => (
  <FuseSvgIcon {...rest}>{`notifications:${iconId}`}</FuseSvgIcon>
);

export {
  Status,
  Feather,
  Workflow,
  FilesIcons,
  CommonIcons,
  MaterialSolid,
  HeroIconsSolid,
  MaterialOutline,
  HeroIconsOutline,
  NotificationsIcons,
};
