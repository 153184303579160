export const getStyles = (variant) => {
  const tabClasses = 'rounded-50 py-8 px-20 w-1/2 min-h-full';
  const tabsListClasses = 'm-16 border-solid border border-gray-200 shadow-4 bg-gray-50 p-6 rounded-24 h-50';

  switch (variant) {
    case 'primary':
      return {
        tabClasses,
        tabsListClasses,
        sx: {
          '& .MuiTabs-indicator': { display: 'none' },
          '& .MuiTab-root': {
            '&.Mui-selected': {
              color: 'secondary',
            },
          },
          '& .Mui-selected': {
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'gray.main',
          },
        },
      };
    case 'secondary':
      return {
        tabClasses: '',
        tabsListClasses: '',
        sx: {
          '&.MuiTabs-root': {
            minHeight: 'auto',
          },
          '& .MuiTabs-scroller': {
            height: '40px',
          },
          '& .MuiTab-root': {
            minHeight: 'unset',
            flexDirection: 'row',
            gap: '6px',
            alignItems: 'center',
            padding: '10px 12px',
            '& .MuiTab-iconWrapper': {
              margin: 0,
            },
          },
          '& .MuiTabs-indicator': { borderRadius: '8px 8px 0 0 ' },
        },
      };

    case 'block':
      return {
        tabClasses: '',
        tabsListClasses: '',
        sx: {
          '& .MuiTab-root': {
            color: 'accent.900',
            padding: '8px 12px',
            borderRadius: '6px',
            minHeight: 'unset',

            '&:hover': {
              backgroundColor: 'accent.100',
            },

            '&.Mui-selected': {
              color: 'accent.900',
              backgroundColor: 'accent.200',
            },
          },
          '& .MuiTabs-indicator': { display: 'none' },
        },
      };

    default:
      return { tabClasses: '', tabsListClasses: '', sx: {} };
  }
};
