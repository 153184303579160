import { createSelector, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtService from '../../services/jwtService';

export const setUserData = (user) => async (dispatch, getState) => {
  const adaptedUser = {
    role: user.role,
    data: {
      id: user.id,
      displayName: user.fullName,
      email: user.email,
      photoURL: user.photo,
      clientKey: user.clientKey,
      clientStatus: user.clientStatus,
      shortcuts: [],
      permissions: user?.role?.permissions,
      twoFactorEnabled: user.twoFactorEnabled,
      twoFactorVerified: user.twoFactorVerified,
      liveMode: user?.client?.livemode,
    },
  };

  /*
		Set User Settings
	*/
  dispatch(setDefaultSettings(adaptedUser.data.settings));

  dispatch(setUser(adaptedUser));

  /*
		You can redirect the logged-in user to a specific route depending on his role
	*/
  /* history.push('')  // for example 'apps/academy' */
};

export const enable2fa = () => async (dispatch, getState) => {
  jwtService
    .turnOn2fa()
    .then(() => {
      dispatch(setEnable2fa());
      dispatch(showMessage({ message: 'Turn on two factor auth for client' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

export const disable2fa = () => async (dispatch, getState) => {
  jwtService
    .turnOff2fa()
    .then(() => {
      dispatch(setDisable2fa());
      dispatch(showMessage({ message: 'Turn off two factor auth for client' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

export const setQRCode = (code) => async (dispatch, getState) => {
  dispatch(set2faQRCode(code));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: '/sign-in',
  });

  jwtService.logout();

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  QRCode: null,
  role: undefined, // guest
  data: {
    displayName: '',
    photoURL: '',
    email: '',
    shortcuts: [],
    twoFactorEnabled: false,
    permissions: undefined,
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    updateUserPhoto: (state, { payload }) => {
      state.data.photoURL = payload;
    },
    setEnable2fa: (state) => {
      state.data.twoFactorEnabled = true;
    },
    setDisable2fa: (state) => {
      state.data.twoFactorEnabled = false;
    },
    set2faQRCode: (state, action) => {
      state.QRCode = action.payload;
    },
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

const entityStore = (state) => state.auth;
export const authSelectors = {
  selectCurrentUser: createSelector(entityStore, (state) => state.user),
  currentRole: createSelector(entityStore, (state) => state.user.role),
  currentUserId: createSelector(entityStore, (state) => state.user.data.id),
  selectUserPermissions: createSelector(entityStore, (state) => state.user.data.permissions),
  selectTwoFactorEnabled: createSelector(entityStore, (state) => state.user.data.twoFactorEnabled),
};

export const { setUser, userLoggedOut, setEnable2fa, setDisable2fa, set2faQRCode, updateUserPhoto } =
  userSlice.actions;

export default userSlice.reducer;
