export default {
  NOTIFICATIONS: 'Сповіщення',
  MARK_ALL_AS_READ: 'Позначити всі як прочитані',
  APPLICANT_CREATED: 'Профіль кандидата створено',
  APPLICANT_STEP_PASSED: 'Кандидат завершив етап перевірки <strong>{{step}}</strong>',
  APPLICANT_STATUS_UPDATED:
    'Статус перевірки користувача <strong>{{user}}</strong> змінено на <strong>{{status}}</strong>',

  DOCUMENT_WARNING:
    'Під час перевірки <strong>{{fullName}}</strong> знайдено попередження: <strong>{{warning}}</strong>',
  APPLICANT_DUPLICATE_DOCUMENT: 'Дублікований документ кандидата',

  // TODO
  AML_CANNOT_STARTED: 'Перевірку AML не можна розпочати',
  AML_RESULT_UPDATED: 'Результат AML оновлено',
  AML_POSITIVE_CASES: 'Позитивні випадки AML',
  APPLICANT_AML_UPDATED: 'AML кандидата оновлено',

  APPLICANT_IN_REVIEW: 'Кандидат готовий до перегляду.',
  THERE_ARE_NO_UNREAD_NOTIFICATIONS: 'Немає непрочитаних сповіщень',

  INIT: 'Ініційовано',
  INCOMPLETE: 'Незавершено',
  IN_PROGRESS: 'У процесі',
  ON_HOLD: 'В очікуванні',
  COMPLETED: 'Завершено',
  IN_REVIEW: 'На розгляді',
  APPROVED: 'Схвалено',
  REJECTED: 'Відхилено',
  ACCEPTED: 'Прийнято',
};
