import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotificationsCounter = ({ count }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      borderRadius: '20px',
      backgroundColor: 'red.900',
      border: '1px solid',
      borderColor: 'common.white',
      padding: '4px',
      minWidth: '15px',
      height: '16px',
      top: 0,
      right: 0,
    }}
  >
    <Typography variant="caption" fontSize="10px" color="common.white" fontWeight={600}>
      {count}
    </Typography>
  </Box>
);

export default NotificationsCounter;
