import { lazy } from 'react';
import i18next from 'i18next';

import { PATHS, TRANSLATION_KEY } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

const Applications = lazy(() => import('./Applications'));
const ApplicationDetails = lazy(() => import('./ApplicationDetails'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const ApplicationsNavigationConfig = {
  id: 'applications',
  title: 'Applications',
  translate: 'APPLICATIONS',
  type: 'item',
  icon: 'navigation:applications',
  url: PATHS.APPLICATIONS,
  auth: [
    PERMISSIONS.ListApplications.value,
    PERMISSIONS.ApplicationAll.value,
    PERMISSIONS.OnlyTestMode.value,
  ],
};

export const ApplicationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [
    PERMISSIONS.ListApplications.value,
    PERMISSIONS.ApplicationAll.value,
    PERMISSIONS.OnlyTestMode.value,
  ],
  routes: [
    {
      path: PATHS.APPLICATIONS,
      children: [
        {
          path: '',
          element: <Applications />,
        },
        {
          path: PATHS.APPLICATION_DETAILS,
          element: <ApplicationDetails />,
        },
      ],
    },
  ],
};
