import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, darken, IconButton } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

import { AVATAR_TYPES } from 'app/constants/client';
import PermissionsGate from 'app/main/teams/components/PermissionsGate';

import Avatar from '../Avatar';

const AvatarUploader = ({ size = 96, id, photo, type, onSetAvatarFile, scopes = [], name }) => {
  const [newAvatar, setNewAvatar] = useState(null);

  useEffect(() => onSetAvatarFile(newAvatar?.file), [newAvatar, onSetAvatarFile]);

  function onUploadAvatar(event) {
    if (event.target.files.length) {
      const [avatar] = event.target.files;

      const reader = new FileReader();
      reader.onloadend = () =>
        setNewAvatar({
          preview: reader.result,
          file: avatar,
        });
      reader.readAsDataURL(avatar);
    }
  }

  const UploadComponent = () => (
    <PermissionsGate scopes={scopes}>
      <Box
        className="avatarOverlay"
        sx={{
          display: 'none',
          position: 'absolute',
          width: size,
          height: size,
          opacity: 0.6,
          background: darken('#fff', 0.8),
          zIndex: 1,
          borderRadius: '50%',
        }}
      />
      <Box
        className="avatarActions"
        sx={{
          display: 'none',
          position: 'absolute',
          zIndex: 1,
          height: size,
          width: size,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton component="label" className="text-white" size="small">
          <PhotoCamera />
          <input type="file" hidden onChange={onUploadAvatar} name={name} />
        </IconButton>
      </Box>
    </PermissionsGate>
  );

  return (
    <Box
      sx={{
        '&:hover > .avatarOverlay': {
          display: 'flex',
        },
        '&:hover > .avatarActions': {
          display: 'flex',
        },

        imageInput: {
          display: 'none',
        },
      }}
    >
      <UploadComponent />
      <Avatar
        size={size}
        alt="user avatar"
        type={type}
        path={!newAvatar?.preview && photo}
        src={newAvatar?.preview}
      />
    </Box>
  );
};

AvatarUploader.propTypes = {
  type: PropTypes.oneOf(Object.values(AVATAR_TYPES)),
};

export default AvatarUploader;
