import { AxiosManager } from '../../../services/axiosManager';

class ApplicationsService {
  constructor() {
    this.axios = AxiosManager.getInstance(process.env.REACT_APP_ADMIN_API_URL, 'api/web/client/application');
  }

  async getApplicationById(key) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`/${key}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAll(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/', { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async createApplication({ record, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post('/', record);

      if (typeof callback === 'function') {
        callback(data.key);
      }
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateApplication({ key, record, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${key}`, record);
      if (typeof callback === 'function') {
        callback();
      }
      return { id: key, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateSecretKey({ key }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${key}/secret-key`);
      return { id: key, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateLogo({ key, logo }) {
    const formData = new FormData();
    formData.append('logo', logo);
    try {
      const {
        data: { data },
      } = await this.axios.put(`${key}/logo`, formData);
      return { id: key, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async delete({ key, callback, errorCallback }) {
    try {
      await this.axios.delete(`${key}`);
      if (typeof callback === 'function') {
        callback();
      }
      return key;
    } catch (err) {
      errorCallback?.();
      throw AxiosManager.stringifyError(err);
    }
  }

  async forceDelete({ key, callback }) {
    try {
      await this.axios.delete(`${key}/force`);
      if (typeof callback === 'function') {
        callback();
      }
      return key;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async addRSAKey({ key, publicRSAKey }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${key}/rsa`, { publicRSAKey });
      return { key, publicRSAKey: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deleteRSAKey(key) {
    try {
      const response = await this.axios.delete(`${key}/rsa`);
      return { key, publicRSAKey: null };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new ApplicationsService();
