export const TRANSLATION_KEY = 'applications';

export const PATHS = {
  APPLICATIONS: '/application',
  APPLICATION_DETAILS: '/application/:appKey',
};

export const NOTIFICATIONS_SETTINGS_CHANNELS = {
  SLACK: 'SLACK',
  IN_APP: 'IN_APP',
  WEBHOOK: 'WEBHOOK',
  TELEGRAM: 'TELEGRAM',
};
