import { createAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import { BaseEntityStoreBuilder } from 'app/store/helpers';
import { actions as logsActions } from 'app/main/logs/store/logsSlice';

import { AML_SCAN_MODULE_KEY, AML_CHECK_STATUSES } from '../constants';
import { updateCommentsListInProfile, updateProfileStatus } from '../utils';

import amlScanService from './amlScanService';

const storeBuilder = new BaseEntityStoreBuilder(AML_SCAN_MODULE_KEY, amlScanService);
const entityStore = (state) => state[AML_SCAN_MODULE_KEY];

export const selectors = {
  ...storeBuilder.selectors,
  selectAmlScanId: createSelector(entityStore, (state) => state.amlScanId),
  selectAMLCheckStatus: createSelector(entityStore, (state) => state.status),
  selectAmlMonitoring: createSelector(entityStore, (state) => state.amlMonitoring),
  selectAmlProfileInfo: createSelector(entityStore, (state) => state.amlProfileInfo),
  selectAmlScanResults: createSelector(entityStore, (state) => state.amlScanResults),
  selectAmlDataToExport: createSelector(entityStore, (state) => state.amlDataToExport),
};

export const actions = {
  getAMLList: storeBuilder.createAction('getAMLList'),
  startAMLProcess: storeBuilder.createAction('startAMLProcess'),
  changeCaseStatus: storeBuilder.createAction('changeCaseStatus'),
  changeMatchStatus: storeBuilder.createAction('changeMatchStatus'),
  getAMLScanDetails: storeBuilder.createAction('getAMLScanDetails'),
  checkAMLScanStatus: storeBuilder.createAction('checkAMLScanStatus'),
  exportAMLScanReport: storeBuilder.createAction('exportAMLScanReport'),
  getAMlScanProfileInfo: storeBuilder.createAction('getAMlScanProfileInfo'),
  clearAmlDataState: createAction(`${AML_SCAN_MODULE_KEY}/clearAmlDataState`),
  updateAMLProfileStatus: storeBuilder.createAction('updateAMLProfileStatus'),
  updateOngoingMonitoring: storeBuilder.createAction('updateOngoingMonitoring'),
  enableOngoingMonitoring: storeBuilder.createAction('enableOngoingMonitoring'),
  clearAmlProfileData: createAction(`${AML_SCAN_MODULE_KEY}/clearAmlProfileData`),
  disableOngoingMonitoring: storeBuilder.createAction('disableOngoingMonitoring'),
  createAMLScanProfileComment: storeBuilder.createAction('createAMLScanProfileComment'),
  getAmlScanLogHistory: createAsyncThunk(
    `${AML_SCAN_MODULE_KEY}/getAmlScanLogHistory`,
    async ({ amlScanId, queryParams }, { dispatch }) => {
      const logs = await amlScanService.getAmlScanLogHistory({ amlScanId, queryParams });
      const { offset } = queryParams;

      const total = logs?.length || 0;

      if (logs && offset === 0) {
        dispatch(logsActions.setLogs({ rows: logs, total }));
      }
      if (logs && offset > 0) {
        dispatch(logsActions.addLogs({ rows: logs, total }));
      }
    }
  ),
};

const amlScanSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.startAMLProcess.fulfilled, (state, action) => {
      state.status = AML_CHECK_STATUSES.PROCESS;
      state.amlScanId = action.payload.id;
    })
    .addCase(actions.checkAMLScanStatus.fulfilled, (state, action) => {
      if (action.payload?.aml) {
        state.status = AML_CHECK_STATUSES.DONE;
        state.amlScanResults = action.payload.aml;
        state.amlMonitoring = action.payload?.aml?.amlMonitoring;
      }
    })
    .addCase(actions.checkAMLScanStatus.rejected, (state) => {
      state.status = AML_CHECK_STATUSES.FAIL;
    })
    .addCase(actions.getAMlScanProfileInfo.fulfilled, (state, action) => {
      state.amlProfileInfo = action.payload;
    })
    .addCase(actions.createAMLScanProfileComment.fulfilled, (state, { payload }) => {
      if (payload) {
        state.amlScanResults = updateCommentsListInProfile(state.amlScanResults, payload);
      }
    })
    .addCase(actions.exportAMLScanReport.fulfilled, (state, { payload }) => {
      state.amlDataToExport = payload;
    })
    .addCase(actions.updateAMLProfileStatus.fulfilled, (state, { payload }) => {
      state.amlScanResults = updateProfileStatus(state.amlScanResults, payload);
    })
    .addCase(actions.changeMatchStatus.fulfilled, (state, action) => {
      state.amlScanResults.matchStatus = action?.payload?.matchStatus;
    })
    .addCase(actions.changeCaseStatus.fulfilled, (state, action) => {
      state.amlScanResults.data.caseStatus = action.payload?.status;
    })
    .addCase(actions.getAMLList.fulfilled, (state, action) => {
      state.total = action.payload.total;
      return storeBuilder.adapter.setAll(state, action.payload.rows);
    })
    .addCase(actions.getAMLScanDetails.fulfilled, (state, action) => {
      state.amlScanResults = action.payload;
      state.amlScanId = action.payload.id;
      state.amlMonitoring = action.payload?.amlMonitoring;
    })
    .addCase(actions.clearAmlDataState, (state) => {
      state.amlScanResults = null;
      state.amlScanId = null;
      state.amlMonitoring = null;
    })
    .addCase(actions.clearAmlProfileData, (state) => {
      state.amlProfileInfo = null;
    })
);

export default amlScanSlice.reducer;
