import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ComeBackButton({ onBtnClick, path, title, state, callback = () => {} }) {
  const navigate = useNavigate();

  const handleComeBackClick = () => {
    callback?.();
    if (!path) {
      return navigate(-1);
    }
    navigate(path, { state });
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      component="button"
      onClick={onBtnClick || handleComeBackClick}
    >
      <FuseSvgIcon className="fuse-list-item-icon shrink-0 mr-10">
        heroicons-outline:arrow-sm-left
      </FuseSvgIcon>
      <Typography variant="button">{title}</Typography>
    </Box>
  );
}
