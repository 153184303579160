import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
import * as SVG from 'app/shared-components/Icons';

import { TRANSLATION_KEY } from '../../constants';

const NotificationsHeader = ({ markedAsRead, onMarkAllAsReadClick, unreadCount }) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px 10px 5px 20px">
      <Typography variant="subtitle1" fontWeight={600}>
        {t('NOTIFICATIONS')}
      </Typography>
      {!!unreadCount && (
        <Typography variant="caption" fontSize="10px">
          {unreadCount} unread
        </Typography>
      )}

      <Box
        display="flex"
        alignItems="center"
        padding="0 20px 5px"
        justifyContent="flex-end"
        onClick={onMarkAllAsReadClick}
      >
        {markedAsRead && (
          <SVG.NotificationsIcons
            iconId="check-check"
            size={16}
            sx={{
              color: 'violet.900',
            }}
          />
        )}
        <Typography
          variant="caption"
          color={markedAsRead ? 'violet.900' : 'accent.500'}
          sx={{
            marginLeft: '3px',
            '&:hover': {
              cursor: !markedAsRead ? 'pointer' : 'default',
              textDecoration: !markedAsRead ? 'underline' : 'none',
            },
          }}
        >
          {t('MARK_ALL_AS_READ')}
        </Typography>
      </Box>

      {/* <IconButton>
        <SVG.NotificationsIcons iconId="gear" size="20px" sx={{ color: 'accent.500' }} />
      </IconButton> */}
    </Box>
  );
};

export default NotificationsHeader;
