import { useState } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import * as SVG from 'app/shared-components/Icons';

const Accordion = ({
  title,
  sx = {},
  children,
  summarySx = {},
  CustomTitle,
  defaultOpen = true,
  ...restProps
}) => {
  const [expanded, setExpanded] = useState(defaultOpen);
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiAccordion
      sx={{
        '&.MuiAccordion-root': {
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
          backgroundColor: 'unset',
          border: 'none',
        },
        ...sx,
      }}
      expanded={expanded}
      onChange={toggleAccordion}
      disableGutters
      {...restProps}
    >
      <AccordionSummary
        sx={{
          paddingLeft: 0,
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 0,
          minHeight: '30px',
          height: '30px',
          ...summarySx,
        }}
        expandIcon={<SVG.CommonIcons iconId="carret-down" size={16} />}
      >
        {CustomTitle || <Typography variant="subtitle2">{title}</Typography>}
      </AccordionSummary>
      {expanded && <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>}
    </MuiAccordion>
  );
};

export default Accordion;
