import { Trans } from 'react-i18next';
import { NOTIFICATIONS_EVENTS } from '../constants';

export const getContent = ({ eventName, t, fullName, newStatus, stepName, warning }) => {
  switch (eventName) {
    case NOTIFICATIONS_EVENTS.APPLICANT_CREATED:
      return <Trans i18nKey={t(eventName)} components={{ strong: <strong /> }} />;

    case NOTIFICATIONS_EVENTS.APPLICANT_STEP_PASSED:
      return <Trans i18nKey={t(eventName)} components={{ strong: <strong /> }} values={{ step: stepName }} />;

    default:
      if (
        [
          NOTIFICATIONS_EVENTS.APPLICANT_DUPLICATE_DOCUMENT,
          NOTIFICATIONS_EVENTS.ADDITIONAL_DOCUMENT_STEP_ERROR,
          NOTIFICATIONS_EVENTS.APPLICANT_DUPLICATE_EMAIL,
          NOTIFICATIONS_EVENTS.APPLICANT_DUPLICATE_PHONE,
        ].includes(eventName)
      ) {
        return (
          <Trans
            i18nKey={t('DOCUMENT_WARNING')}
            components={{ strong: <strong /> }}
            values={{ warning: t(warning), fullName }}
          />
        );
      }
      if (
        [
          NOTIFICATIONS_EVENTS.APPLICANT_IN_PROGRESS,
          NOTIFICATIONS_EVENTS.APPLICANT_INCOMPLETE,
          NOTIFICATIONS_EVENTS.APPLICANT_COMPLETED,
          NOTIFICATIONS_EVENTS.APPLICANT_REJECTED,
          NOTIFICATIONS_EVENTS.APPLICANT_APPROVED,
        ].includes(eventName)
      ) {
        return (
          <Trans
            i18nKey={t('APPLICANT_STATUS_UPDATED')}
            components={{ strong: <strong /> }}
            values={{ user: fullName || 'Unknown', status: t(newStatus) }}
          />
        );
      }

      return t(eventName);
  }
};
