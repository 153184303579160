import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import { changeMode, selectors as modeSelectors } from 'app/store/modeSlice';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as SVG from 'app/shared-components/Icons';
import Accordion from 'app/shared-components/Accordion';
import { authSelectors } from 'app/auth/store/userSlice';

import Notification from './components/Notification';
import NotificationsHeader from './components/NotificationsHeader';
import NotificationsCounter from './components/NotificationsCounter';

import * as hooks from './hooks';
import * as utils from './utils';
import { TRANSLATION_KEY } from './constants';
import { PATHS } from '../verifications/constants';

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANSLATION_KEY);
  const [anchorEl, setAnchorEl] = useState(null);

  const currentMode = useSelector(modeSelectors.selectModeType);
  const currentUserId = useSelector(authSelectors.currentUserId);

  const {
    total,
    socket,
    setTotal,
    notifications,
    setNotifications,
    getNotifications,
    markMessageAsRead,
    markAllMessageAsRead,
  } = {
    ...hooks.useNotificationsWebSocket(),
  };

  const markedAsRead = useMemo(
    () => !notifications.some((notification) => !notification.isRead),
    [notifications]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const isNotificationsAvailable = !_.isEmpty(notifications);
  const count = total > 99 ? '99+' : total;

  useEffect(() => {
    if (socket) {
      getNotifications().then((response) => {
        const { data } = response;
        console.log(data);
        setNotifications(data.rows);
        setTotal(data.total);
      });
    }
  }, [socket]);

  const onNotificationClick = useCallback(
    (messageId, isRead, applicantId, workmode) => {
      if (!isRead) {
        markMessageAsRead?.({ messageId }).then(() => {
          setNotifications((prev) =>
            prev.map((notification) =>
              notification.id === messageId ? { ...notification, isRead: true } : notification
            )
          );
        });
      }

      if (workmode !== currentMode) {
        dispatch(changeMode(workmode));
      }

      navigate(PATHS.APPLICANTS_DETAILS.replace(':applicantId', applicantId));
    },
    [currentUserId, markMessageAsRead, currentMode]
  );

  const onMarkAllAsReadClick = useCallback(() => {
    if (!markedAsRead) {
      markAllMessageAsRead().then(() => {
        setNotifications((prev) => prev.map((notification) => ({ ...notification, isRead: true })));
      });
    }
  }, [currentUserId, markedAsRead]);

  const onOpenPopper = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onPopperClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const groupedNotifications = useMemo(
    () => utils.groupByPriority(_.sortBy(notifications, { isRead: true })),
    [notifications]
  );

  const unreadCount = useMemo(() => _.filter(notifications, { isRead: false }).length, [notifications]);

  return (
    <Box>
      <IconButton
        aria-describedby={id}
        onClick={onOpenPopper}
        sx={{
          position: 'relative',
          padding: '5px',
          borderRadius: '6px',
          backgroundColor: open ? 'accent.100' : 'common.white',
          '&:hover': { backgroundColor: 'accent.100', svg: { color: 'accent.800' } },
        }}
      >
        <SVG.NotificationsIcons
          size={20}
          iconId="bell"
          sx={{ color: isNotificationsAvailable ? 'accent.800' : 'accent.500' }}
        />
        {isNotificationsAvailable && <NotificationsCounter count={count} />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        onClose={onPopperClose}
      >
        <Paper
          sx={{
            width: '388px',
            maxHeight: '621px',
            minHeight: '300px',
            overflow: 'auto',
            paddingBottom: '20px',
          }}
        >
          <Box position="sticky" top="0" backgroundColor="common.white" zIndex="10">
            <NotificationsHeader
              unreadCount={unreadCount}
              markedAsRead={markedAsRead}
              onMarkAllAsReadClick={onMarkAllAsReadClick}
            />
          </Box>
          {!isNotificationsAvailable ? (
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="60px">
              <SVG.MaterialSolid iconId="notifications_none" size={80} />
              <Typography variant="subtitle1">{t('THERE_ARE_NO_UNREAD_NOTIFICATIONS')}</Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column">
              {Object.entries(groupedNotifications).map(([key, value]) => {
                if (!value.length) return null;

                return (
                  <Accordion
                    key={key}
                    summarySx={{ paddingLeft: '20px' }}
                    CustomTitle={
                      <Typography variant="caption">
                        {t(key)}{' '}
                        <Typography component="span" variant="inherit" color="accent.900">
                          ({value.length})
                        </Typography>
                      </Typography>
                    }
                  >
                    {value.map((notification) => (
                      <Notification {...notification} key={notification.id} onClick={onNotificationClick} />
                    ))}
                  </Accordion>
                );
              })}
            </Box>
          )}
        </Paper>
      </Popover>
    </Box>
  );
};

export default Notifications;
