import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import FuseDialog from '@fuse/core/FuseDialog';
import * as SVG from 'app/shared-components/Icons';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';

const Dialog = ({
  id,
  title,
  content,
  onClose,
  actions,
  children,
  titleIcon,
  iconSize = 24,
  submitButtonProps,
  cancelButtonProps,
  showCloseIcon = true,
  titleVariant = 'heading5',
  titleColor = 'accent.900',
  closeIconPosition = 'right',
  justifyButtons = 'flex-start',
  ...restProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);
  const closeIconLeft = closeIconPosition === 'left';
  const closeIconRight = closeIconPosition === 'right';

  const onCloseHandler = useCallback(() => {
    onClose?.();
    dispatch(closeDialog());
  }, [dispatch, onClose]);

  return (
    <FuseDialog {...restProps} fullWidth dialogId={id} onClose={onCloseHandler}>
      <Box padding="22px">
        <DialogTitle
          sx={{ '&.MuiDialogTitle-root': { paddingX: 0, paddingTop: 0 } }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // position="sticky"
          // top="22px"
          // bgcolor="common.white"
          // zIndex="4"
        >
          {showCloseIcon && closeIconLeft && (
            <IconButton sx={{ color: 'accent.400' }} onClick={onCloseHandler}>
              <SVG.HeroIconsOutline iconId="x" size={iconSize} />
            </IconButton>
          )}
          <Box display="flex" alignItems="center" gap="5px">
            {titleIcon && titleIcon}
            <Typography variant={titleVariant} color={titleColor} fontWeight={600}>
              {title}
            </Typography>
          </Box>
          {showCloseIcon && closeIconRight && (
            <IconButton sx={{ color: 'accent.900' }} onClick={onCloseHandler}>
              <SVG.HeroIconsOutline iconId="x" size={iconSize} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ paddingX: 0 }}>{content || children}</DialogContent>
        {actions && actions}
        {submitButtonProps && (
          <DialogActions sx={{ paddingX: 0, paddingBottom: 0, justifyContent: justifyButtons }}>
            <Button variant="contained" {...submitButtonProps}>
              <Typography variant="button">{submitButtonProps?.children}</Typography>
            </Button>
            <Button color="gray" variant="contained" onClick={onCloseHandler} {...cancelButtonProps}>
              <Typography variant="button">{cancelButtonProps?.children || t('CANCEL')}</Typography>
            </Button>
          </DialogActions>
        )}
      </Box>
    </FuseDialog>
  );
};

export default Dialog;
