export default {
  APPLICATIONS: 'Додатки',
  KEY: 'Ключ',
  CLIENT_KEY: 'Клієнтський ключ',
  NAME: 'Назва додатку',
  DESCRIPTION: 'Опис',
  STATUS: 'Статус',
  CREATE: 'Створити',
  CREATED_AT: 'Дата створення',
  UPDATED_AT: 'Дата оновлення',
  DEMO_EXPIRE: 'Демо версия минає',
  SAVE: 'Зберегти зміни',
  SAVE_CHANGES: 'Зберегти зміни',
  PLATFORMS: 'Платформи',
  COPY: 'Копіювати',
  COMEBACK: 'Повернутись до додатків',
  DELETE: 'Видалити',
  EDIT: 'Редагувати',
  ADD: 'Додати',
  TYPE: 'Тип',
  ADD_PLATFORM: 'Додати платформу',
  CANCEL: 'Скасувати',
  CREATE_AT: 'Створено',
  APPLICATION_ID: 'id додатка',
  VALUE: 'Значення',

  STATUS_DEVELOP: 'Development',
  STATUS_PRODUCTION: 'Production',
  BUNDLE_ID: 'Bundle id',
  BUNDLE_HELPER: 'Bundle id має бути у форматі URI',
  ORIGIN: 'Origin',
  ORIGIN_LABEL: 'Origin',

  NAME_HELPER: 'Ви повинні ввести назву додатку',
  NAME_ERROR: 'Ви повинні ввести дійсну назву додатку',

  DESCRIPTION_HELPER: 'Опис додатку',
  DESCRIPTION_ERROR: 'Ви повинні ввести опис додатку',

  ADD_APP: 'Створити додаток',
  UPDATE_APP: 'Оновити додаток',
  EDIT_APP: 'Редагувати додаток',
  DELETE_APP: 'Видалити додаток',
  NEW_APP: 'Новий додаток',

  ADD_ORIGIN: 'Додати Origin',
  EDIT_ORIGIN: 'Редагувати список Origin',
  ERROR_ORIGIN: 'Введіть коректне доменне імʼя',

  EDIT_PLATFORM: 'Редагувати платформу',
  REDIRECT_URL: 'Redirect URL',
  URL_ERROR: 'Ви повинні ввести валідну URL-адресу',

  CRYPTO_KEY: 'Crypto key',
  WEBHOOK_SETTING: 'Налаштування вебхука',
  WEBHOOK_URL: 'URL вебхука',
  CONFIRM_UPDATE_CRYPTO_KEY: 'Ви впевнені, що хочете оновити криптографічний ключ?',
  UPDATE: 'Оновити',
  ADD_HEADER: 'Додайте заголовки відповідей',
  PRIVATE_APP_KEY: 'Приватний ключ додатку',
  SEARCH_BY_NAME: 'Пошук за назвою',

  EMPTY_STATE_TITLE: 'Застосунки',
  EMPTY_STATE_DESCRIPTION:
    'Ще не створено жодного застосунку. Застосунки допомагають вам управляти проєктами в межах вашої організації. Розпочніть, створивши один із них.',

  APPLICATION_CREATED: 'Заявку успішно створено',
  APPLICATION_UPDATED: 'Заявку успішно оновлено',
};
