import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from 'app/shared-components/Avatar';
import * as SVG from 'app/shared-components/Icons';
import { AVATAR_TYPES } from 'app/constants/client';
import StatusIcon from 'app/shared-components/StatusIcon';

import { TRANSLATION_KEY } from '../../constants';
import { getContent } from '../../utils/getContent';

const Notification = ({ id, createdAt, isRead, eventName, data, onClick }) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const {
    previousStatus,
    editor,
    newStatus,
    fullName,
    stepName,
    id: applicantId,
    warning,
    workmode,
  } = { ...data };
  const content = getContent({ eventName, t, fullName, newStatus, stepName, warning });

  return (
    <Box
      onClick={() => onClick(id, isRead, applicantId, workmode)}
      sx={{
        gap: '10px',
        display: 'flex',
        cursor: 'pointer',
        padding: '8px 12px 8px 20px',
        backgroundColor: !isRead ? 'violet.100' : 'common.white',
        '&:hover': {
          backgroundColor: 'violet.200',
        },
      }}
    >
      <Box>
        <Avatar path={editor?.photo} sx={{ width: '32px', height: '32px' }} type={AVATAR_TYPES.ADMIN_USER} />
      </Box>
      <Box>
        <Box display="flex" gap="3px" alignItems="center" marginBottom="3px">
          {previousStatus && <StatusIcon status={previousStatus} size={12} tooltipText={t(previousStatus)} />}
          {previousStatus && newStatus && (
            <SVG.Feather iconId="arrow-right" size={10} sx={{ color: 'accent.500' }} />
          )}
          {newStatus && <StatusIcon status={newStatus} size={12} tooltipText={t(newStatus)} />}
        </Box>
        <Typography variant="body2" marginBottom="5px">
          {content}{' '}
        </Typography>
        <Typography variant="caption" display="flex" alignItems="center">
          {format(new Date(createdAt), 'HH:mm MMMM dd')}
          {editor?.name && (
            <Box
              width="4px"
              height="4px"
              margin="0 6px"
              component="span"
              borderRadius="50%"
              backgroundColor="accent.500"
            />
          )}
          {editor?.name}
        </Typography>
      </Box>
      {!isRead && (
        <Box margin="auto 0">
          <Box width="10px" height="10px" borderRadius="50%" backgroundColor="violet.900" margin="auto 0" />
        </Box>
      )}
    </Box>
  );
};

export default Notification;
