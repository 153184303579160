import { Box } from '@mui/material';
import { VERIFICATION_STATUS } from 'app/main/verifications/constants';
import { Status } from '../Icons';
import Tooltip from '../Tooltip';

const getColor = (status) => {
  switch (status) {
    case VERIFICATION_STATUS.INIT:
      return '#5183F6';
    case VERIFICATION_STATUS.APPROVED:
      return 'success.500';
    case VERIFICATION_STATUS.COMPLETED:
      return 'success.main';
    case VERIFICATION_STATUS.INCOMPLETE:
      return 'violet.900';
    case VERIFICATION_STATUS.IN_PROGRESS:
      return 'accent.800';
    case VERIFICATION_STATUS.IN_REVIEW:
      return 'violet.900';
    case VERIFICATION_STATUS.REJECTED:
      return 'error.main';
    default:
      return '#5183F6';
  }
};

const StatusIcon = ({ tooltipText, status, ...rest }) => {
  if (tooltipText) {
    return (
      <Tooltip text={tooltipText}>
        <Box>
          <Status iconId={status.toLowerCase()} sx={{ color: getColor(status) }} {...rest} />
        </Box>
      </Tooltip>
    );
  }

  return <Status iconId={status.toLowerCase()} sx={{ color: getColor(status) }} {...rest} />;
};

export default StatusIcon;
